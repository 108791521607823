/* eslint-disable max-len */
import * as falso from '@ngneat/falso';
import { AdminAssessment } from 'types/AdminAssessment';

import { createMockFolder } from './folders';
import { AssessmentStatus } from '../types/AssessmentStatus';

falso.seed('adminassessment-mock');
const idFactory = falso.incrementalNumber();

const createMockAdminAssessmentData = (
  { leader }: { leader?: Leader } = {},
): AdminAssessment => ({
  entitlementId: idFactory().toString(),
  product: falso.rand(['lpi-360', 'lpi-360-plus', 'lpi-self', 'lpi-self-empowered', 'pre-work', 'lpi-student-360', 'lpi-student-self']),
  firstName: leader?.firstName || falso.randFirstName(),
  lastName: leader?.lastName || falso.randLastName(),
  email: leader?.email || falso.randEmail(),
  userId: leader?.userId || idFactory().toString(),
  createdAt: '2023-03-23',
  completedAt: falso.randBoolean() ? '2023-03-23' : undefined,
  status: falso.rand([
    AssessmentStatus.ACCEPTED,
    AssessmentStatus.COMPLETED,
    AssessmentStatus.INVITATION_SENT,
    AssessmentStatus.IN_PROGRESS,
  ]),
  folder: createMockFolder(),
  surveyComplete: falso.randBoolean() ? '2023-03-23' : undefined,
  reassessmentDate: falso.randBoolean() ? '2023-03-23' : undefined,
  managerComplete: falso.randBoolean(),
  observers: falso.randBoolean() ? falso.randFullName() : undefined,
});

export const createMockAdminAssessment = (
  { leader }: { leader?: Leader } = {},
): AdminAssessment => createMockAdminAssessmentData({ leader });

export const createMockAdminAssessments = (
  { length = falso.randNumber({ min: 0, max: 100 }), leader }: { length?: number; leader?: Leader } = {},
): AdminAssessment[] => (
  Array.from({ length }, () => createMockAdminAssessmentData({ leader }))
);
