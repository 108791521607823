import * as falso from '@ngneat/falso';

falso.seed('folders-mock');
const idFactory = falso.incrementalNumber();


export const createMockFolder = (): Folder => ({
  folderId: idFactory(),
  folderName: falso.randCompanyName(),
});

export const createMockLongFolder = (): Folder => ({
  folderId: idFactory(),
  // eslint-disable-next-line max-len
  folderName: 'Lorem ipsum dolor sit amet consectetur. Blandit nunc nisi integer placerat dignissim facilisi quis. Sagittis interdum sem orci libero auctor augue. Tortor malesuada congue lacinia etiam amet integer ut magna. Urna est arcu scelerisque netus viverra libero.',
});

export const createMockFolders = (length?: number): Folder[] => (
  Array.from({ length: length || falso.randNumber({ min: 0, max: 100 }) }, () => createMockFolder())
);

export const createMockLongFolders = (length?: number): Folder[] => (
  Array.from({ length: length || falso.randNumber({ min: 0, max: 100 }) }, () => createMockLongFolder())
);

export const createMockSubFolder = (): SubFolder => ({
  subFolderId: idFactory(),
  subFolderName: falso.randCompanyName(),
});

export const createMockSubFolders = (length?: number): SubFolder[] => (
  Array.from({ length: length || falso.randNumber({ min: 0, max: 100 }) }, () => createMockSubFolder())
);

export const createMockLongFolderWithSubfolders = (): Folder => ({
  folderId: idFactory(),
  // eslint-disable-next-line max-len
  folderName: 'Lorem ipsum dolor sit amet consectetur. Blandit nunc nisi integer placerat dignissim facilisi quis. Sagittis interdum sem orci libero auctor augue. Tortor malesuada congue lacinia etiam amet integer ut magna. Urna est arcu scelerisque netus viverra libero.',
  subFolders: createMockSubFolders(falso.randNumber({ min: 0, max: 3 })),
});

export const createMockLongFoldersWithSubfolders = (length?: number): Folder[] => (
  Array.from({ length: length || falso.randNumber({ min: 0, max: 100 }) }, () => createMockLongFolderWithSubfolders())
);
